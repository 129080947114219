import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { TranslateMeLogin } from './Components/Login/TranslateMeLogin';
import { TranslateMeDocument } from './Components/Document/Document';
import { TranslateMeForm } from './Components/Form/form';
import { TranslateMeHeader } from './Components/Header/header';







class TranslateMe extends React.Component {

  constructor(props){
    super(props);
    this.state={
     user: null,
     //  user: {
     //    name : "Example User",
     //    token:"eb7a533072d63629e2d38cb5c139fe3beb7a5330",
     //  },
      documents:null,
      languages:null,
      document:null,
      documentLang:"en",
      desiredLang:null,
      pending : false,
      documentPage : 1,
      documentPages: 1,
      reload:null
    }
  }

  async loginUser(username,password){
    
    //perform the actual login and return true or false
    let credetials={
      'grant_type' :'password',
      'client_id' : 6 ,
      'client_secret' : 'WebClientSecret',
      'username' : username,
      'password' : password
    };
    let requestBody = JSON.stringify(credetials);
    fetch( this.props.baseUrl + '/oauth/access_token',
        { 
          method : 'POST',
          headers:{ 
           'Content-Type':'application/json',
          },
          body: requestBody
        }
    )
    .then( (response ) => {
      if( !response.ok ){ throw new Error("Invalid login credentials");}
      return response.json();
    })
  .then( (data) =>{
        if( data.hasOwnProperty("access_token") && data.hasOwnProperty("refresh_token")){
          let user = {
            'token' : data.access_token,
            'refresh_token' : data.refresh_token,
            'name' : username
          };
          this.setState( {user : user } );
          this.loadUserData();
        
        }
   })
   .catch( (error)=>{
      this.logout();
   });
    
   return this.state.user!=null;
  }

  logout(){
    this.setState( {user : null , documents : null , langugaes: null } );
  }


  loadUserData(){
    if( this.state.user ){
      this.loadLanguages()
      this.loadDocuments();
    }
  }
  
  componentDidMount(){
    this.loadUserData();
  }

  loadLanguages(){
    //run off and get our langs and store em in state..
    if( 
      this.props.baseUrl 
      && this.state.user 
      && this.state.user.token 
      ){

      let url =  this.props.baseUrl + "/api/language?access_token=" + this.state.user.token ;
      fetch( url )
      .then( res => res.json() )
      .then( (result) => { 
          if( result ){
            this.setState( { languages : result , desiredLang : Object.keys(result)[0] } );
          }
          
      });
    }
  }

  loadDocuments( page ){
   
    if( 
      this.props.baseUrl 
      && this.state.user 
      && this.state.user.token 
      ){

        if( this.state.reload!==null){
          clearTimeout( this.state.reload );
          console.log("timeoutcleared...");
        }
        console.log(" loadDocuments....")
        console.log( "page" , page );
        console.log( "statepage" , this.state.documentPage );

 if( page===undefined 
          && this.state.documentPage!==undefined){
          page = this.state.documentPage;
      }
      
      let url = this.props.baseUrl +"/api/client/history?access_token="+this.state.user.token ;
      
      console.log( "page updated to : ", page );
      if( page !== undefined ){
    
        url= url + "&page=" + page
      }

      fetch( url )
      .then( res => res.json() )
      .then( (result) => {
        //process my documents into a state object
        //console.log( result.conversions[0].result );
        if( result.pages && result.page ){
            this.setState( { 
              documentPages : result.pages,
              documentPage : result.page 
             } );
          
        }
        if( result.user ){
          const user = this.state.user;
          user.name = result.user['name'];
          if( user ){
            this.setState( { user : user } );
          }
        }
        if( 
          result.conversions 
          && result.conversions.length > 0
        ){
          let pending = false;
            const documents = result.conversions.map( (data, index) => {
              if( data.result && data.result.length>0 ){
                let result = data.result[0];
                let link = result.download + "?access_token=" + this.state.user.token;
                let lang = result.language;
                
                if( this.state.languages && this.state.languages.hasOwnProperty(lang)){
                    lang = this.state.languages[lang] + " (" + lang  +")";
                }

                return ( 
                    <TranslateMeDocument 
                      key={index} 
                      documentLink={link} 
                      documentName={result.name}
                      documentMime={result.mime}
                      documentLanguage={lang}
                      documentStatus={data.status}
                      documentData = { result } 
                  />
                )
              }
              else{
                pending = true;
                return ( 
                  <TranslateMeDocument 
                    key={index} 
                    documentLink={ null } 
                    documentName='new document processing..'
                    documentMime='doc'
                    documentLanguage='-'
                    documentStatus={data.status}
                    documentData = { null } 
                />
              )
              }
            });
        
            if( documents && documents.length>0 ){
              this.setState( 
                { documents: documents , 
                  pending : pending
                } 
                  );
            }

            if( pending ){
              let reload = setTimeout( ()=>{this.loadDocuments()}, 5000 );
              this.setState(
                { reload : reload }
              );
            } 
          }
      });
    }
  }


  handleLangChange(e){

      if( e.target.id === "sourcelanguage"){
        this.setState( {
          documentLang : e.target.value
        } );
      }

      if( e.target.id === "targetlanguage"){
        this.setState( {
          desiredLang : e.target.value
        } );
      }

  }

  handleFileChange(e){
    if( e.target.files && e.target.files.length>0 ){
      this.setState({
        document : e.target.files[0]
      });
    }
  }

  submitForm(e){
    e.preventDefault();
    
    console.log(this.state.documentLang) ;
    console.log(this.state.desiredLang) ;
    if( this.state.document 
        && this.state.desiredLang 
        && this.state.documentLang
        && this.state.documentLang!==this.state.desiredLang
      ){
      //great submit it!
      const url = this.props.baseUrl+"/api/request?access_token="+this.state.user.token;
      const formData = new FormData();
      formData.append('source_language' , this.state.documentLang );
      formData.append('target_language' , this.state.desiredLang );
      formData.append('source',this.state.document);
      formData.append('translation_provider',1 );
      //formData.append('output_mimetype','docx');
      fetch(url, 
        {
          method:'POST',
          body : formData
        } 
      )
      .then( res => res.json() )
      .then( (result) => { 
          //always go back to page one on form submit...
            this.loadDocuments( 1 );
          }
        );
      }

      //set some state on teh form maybe?
  }

  render(){
    let documents =<LoadingHolderTr colspan="5" />;
    if( this.state.documents ){
      documents = this.state.documents ;
    }
    

    let form = <LoadingHolder />;
    if( this.state.languages){
      form = <TranslateMeForm 
        languages={this.state.languages} 
        onSubmit={ (e)=>this.submitForm(e) } 
        langChange={ (e)=>this.handleLangChange(e) }
        fileChange={ (e)=>this.handleFileChange(e) }
      />
    }

    let paginator = <Paginator
        page = { this.state.documentPage }
        pages = { this.state.documentPages}
        callback = { (page)=>{ this.loadDocuments(page) } }
      />
    

    if( this.state.user ){
      return (
          <div className="outerWrapper">
            <div className="headerWrapper">
              <TranslateMeHeader user={this.state.user} logout={()=>this.logout()}/>
              </div>
              <div className="bodyWrapper">
                <div className="formWrapper">
                  { form }
                </div>
                <div className="documentListWrapper">
                  <h4>My Translations</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>Status</th>
                        <th>Language</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents}
                    </tbody>
                  </table>
                  {paginator}
                </div>
              </div>
          </div>
      );
    }

    return ( <div className="outerWrapper"><TranslateMeLogin callback={ (u,p)=> this.loginUser(u,p) }/></div> );
  }

}




function LoadingHolder(props){
  return ( <div className="loading">loading</div> );
}

function LoadingHolderTr(props){
  return ( <tr className="loading"><td clspan={props.colspan}>loading</td></tr> );
}


function Paginator(props){
  let downLink = "";
  let downLinkAll = "";
  if( props.page >1 ){
    downLink = <PaginatorLink  
    callback={(e)=>{ 
      e.preventDefault();
      props.callback(props.page-1) } 
    } />

    downLinkAll = <PaginatorLink  
    all='true'
    callback={(e)=>{ 
      e.preventDefault();
      props.callback(1) } 
    } />
  }
  let uplink = "";
  let uplinkAll = "";
  
  if(props.page < props.pages){
    uplink = <PaginatorLink 
    up = 'true'
    callback={(e)=>{ 
      e.preventDefault();
      props.callback(props.page+1) } 
    } />

    uplinkAll = <PaginatorLink 
    up = 'true'
    all = 'true'
    callback={(e)=>{ 
      e.preventDefault();
      props.callback(props.pages) } 
    } />
  }


  return (

    <div className="paginator" >
        {downLinkAll}
        {downLink}
        <span className="paginationText">
           Page {props.page} of {props.pages}
        </span>
        {uplink}
        {uplinkAll}
    </div>

  );

}


function PaginatorLink(props){
  let str = "<";
  let hash ="#pageDown";
  if(props.up){
    str=">";
    hash ="#pageUp";
  }
  if(props.all){
    str = "|<<"
    hash ="#firstPage";
  }
  if(props.all && props.up){
    str = ">>|"
    hash ="#finalPage"
  }
  return (
      <a href={hash} onClick ={(e)=>props.callback(e) }>{str}</a>
  );
}


// ========================================
  
ReactDOM.render(
    <TranslateMe  baseUrl="https://translatemev2.reciteme.com" />,
    document.getElementById('root')
  );
  